.App {
  text-align: center;
}

/* Header */
.Header {
  height: var(--headerHeight);
}

.navbar {
  height: var(--headerHeight);
}

.settings-dropdown {
  color: black;
}

#navbar-brand {
  color: rgb(0, 217, 255);
  font-weight: bold;
}

/*
.evalsAvailable {
  color: green;
}
*/

/* TestWorkFlow */
.TestWorkFlow {
  text-align: left;
  padding-left: 20px;
}

.addNewSiteButtonDiv {
  padding: 20px;
}

.newSiteNameInput {
  width: 20%;
  margin-right: 5px;
}

.runningLoadSpinner {
  display: inline-block;
}

.view_Results_Button {
  margin-left:12px;
}

/* Map */
.mapboxgl-canvas-container {
  height: 350px;
}

.site-marker {
  background-image: url("images/logo.jpg");
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.source-marker {
  background-image: url("images/logo.jpg");
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.MLResultsGraph {
  width:800px;
  height: 600px;
  margin: -100px;
}